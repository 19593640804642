@import "../m-variables";

input[type="date"],
input[type="text"],
input[type="number"],
textarea.a-input {
  font-family: $font-app;
  border-radius: $border-radius-box;
  height: auto;
  padding: 0.8rem;
  font-size: $font-normal;
  color: #000;
  font-weight: normal;
  background-color: $lightgrey;
  border: $input-border-normal;
}

select.a-input {
  background-color: #f2f2f2;
  border: 1px solid #ccd2de;
  border-radius: 0.5555555556rem;
  color: #000;
  font-family: Tisa Sans,sans-serif;
  font-size: 1.1111111111rem;
  font-weight: 400;
  height: auto;
  padding: 0.8rem;
  width: 100%;
}

input[type="file"] {
  color: gray;
  background-color: transparent;
}

/* stylelint-disable */
input[type="file"]::-webkit-file-upload-button,
input[type="file"]::file-selector-button {
  border: 0;
  background-color: #434343;
  padding: 0.8rem;
  color: white;
  border-radius: $border-radius-box;
}
/* stylelint-enable */

.a-label__label {
  font-weight: 600;
  font-size: $font-normal;
  padding-left: 0.8rem;
  padding-bottom: 0.5rem;
}

.a-input-wrapper .a-label-term {
   margin-left: 2em;
   margin-bottom: 0;
}

.drop-list {
  flex-basis: unset !important;
  min-width: 9rem; // in order to overwrite the framework
  font-size: $font-small;
  font-family: $font-app;
  font-style: italic;
  font-weight: 600;
  color: $primary-color;
  line-height: 1rem;
  padding: 1rem 1.6rem 1rem 3rem;
  width: auto;
  box-sizing: border-box;
  border: 0.15rem solid $primary-color;
  border-radius: 1.6rem;
  appearance: none;
  background:
    url("../images/icons/arrow-fill-down.svg") 1.4rem 52%
    no-repeat
    padding-box;
  background-size: 12%;
  transition: background 0.3s;

  &:hover {
    background:
      $primary-color
      url("../images/icons/arrow-fill-up-white.svg") 1.4rem 50% no-repeat
      padding-box;
    background-size: 12%;
    color: $white;
  }
}

.drop-list-nav {
  position: absolute;
  min-width: 5.6rem;
  left: 50%;
  margin-left: -2.8rem;
  background-color: $white;
  border: 0.02rem solid $primary-color;
  border-radius: 0 0 $border-radius-box $border-radius-box;
  z-index: map-get($z-indexs, drop-list-nav);

  @include desktop() {
    margin-left: -1.4rem;
  }

  a {
    flex-basis: unset !important; // in order to overwrite the framework
    display: block;
    padding: 0.2rem 0;
    width: 100%;
    border-top: 0.02rem solid $primary-color;
    background-color: $white;
    color: $primary-color;
    text-decoration: none;
    font-weight: 600;

    &:hover {
      background-color: $primary-color;
      color: $white;
    }

    &:last-child {
      border-radius: 0 0 $border-radius-box $border-radius-box;
    }
  }
}

label.input-hidden {
  display: none;
}
