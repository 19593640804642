@import "../m-variables";
@import "responsive";

.campaign-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .intro-text {
    padding: 10px;
    font-family: inherit;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 500;

    @include desktop() {
      font-size: 20px;
      margin-left: 150px;
      margin-right: 150px;

      p {
        margin-left: 150px;
        margin-right: 150px;
      }
    }
  }

  .campaign-text {
    margin: 10px;

    iframe {
      height: 1360px;
    }
  }

  .campaign-image {
    width: 100%;

    @include desktop() {
      max-width: 1200px;
    }
  }
}

.article-section {
  padding-bottom: 2rem;
  padding-top: 1rem;
  max-width: $max-width;
  margin: 0 auto;

  iframe {
    width: 100%;
    height: auto;
    min-height: 14rem;

    @include tablet() {
      min-height: 19rem;
    }
  }

  .m-media {
    margin: 0 1rem 0 1rem;

    @include tablet() {
      margin: 0;
    }

    img {
      border-radius: $border-radius-box;
      width: 100%;
    }
  }

  .content-meta {
    margin-top: 2rem;
    text-align: center;
    font-weight: 600;
  }

  h1.t-heading--article {
    font-family: $font-app;
    color: $primary-color;
    font-size: $font-title-medium;
    font-weight: 600;
    max-width: 90%;
    text-align: center;
    margin: auto;
    padding-top: 2rem;

    @include tablet() {
      max-width: 75%;
      font-size: $font-title;
    }
  }

  .t-preface {
    text-align: center;
    font-size: $font-intro;
    margin: 2rem auto 4rem auto;
    max-width: 90%;
    color: $black;

    p a {
      color: $primary-color;
      font-size: $font-normal;

      &:hover {
        color: $secondary-color;
      }
    }

    @include tablet() {
      max-width: 60%;
    }
  }

  .t-body {
    position: relative;
    font-size: $font-body;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;

    @include tablet() {
      max-width: 60%;
    }

    h2 {
      color: $primary-color;
      font-weight: 600;
    }

    h3 {
      font-size: $font-normal;
      font-weight: 600;
    }

    figure {
      margin: 0;

      @include tablet() {
        margin: 0 -5rem;
      }
    }

    img {
      border-radius: $border-radius-box;
      max-width: 100%;
    }

    p {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    ol {
      padding-left: 0;
      margin-bottom: 3rem;
      list-style: none;
      counter-reset: article-counter;

      $ul-li-adjust-left: 3rem;

      li {
        counter-increment: article-counter;
        border-bottom: 1px solid $grey;
        padding: 1.5rem 2rem;
        position: relative;

        @include tablet() {
          padding: 1.5rem $ul-li-adjust-left;
          margin-left: -$ul-li-adjust-left;
        }

        &::before {
          content: counter(article-counter);
          position: absolute;
          left: 0;
          color: $primary-color;
          font-size: $font-title-medium;
          font-weight: 600;
          line-height: $font-title-medium;
        }
      }
    }
  }
}

.content-list {
  width: 90%;
  margin: 2rem auto;
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  grid-gap: 2rem;

  @include tablet() {
    width: 100%;
    grid-template-columns: auto auto auto;
    grid-row-gap: 2rem;
  }
}

.years-filter {
  text-align: center;
  margin: 2rem auto;
  width: 100%;

  form {
    display: flex;
    justify-content: center;
  }

  @include desktop() {
    width: 6.4rem;
  }
}
