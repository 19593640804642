@import "../m-variables";
@import "responsive";

.di-recipe {
  margin: auto;
  padding: 4rem 0;
  width: 100%;
  text-align: center;

  .recipe-title {
    font-family: $font-app;
    color: $primary-color;
    font-size: $font-title;
    font-weight: 600;
    max-width: 90%;
    text-align: center;
    margin: auto;
    padding-top: 1rem;

    @include tablet() {
      max-width: 75%;
    }
  }

  h2 {
    width: 100%;
    text-align: center;
    margin: 4rem 0 2rem 0;
  }

  @include tablet() {
    padding: 4rem 0;
    max-width: 67rem;
  }

  .video-transcription {
    color: $darker-gray;
    font-size: $font-small;
  }

  .p-intro-text {
    margin: 2rem 1rem 3rem 1rem;

    p {
      text-align: center;
      font-size: $font-intro;
    }

    @include tablet() {
      margin: 2rem auto;
      max-width: 50%;
    }
  }

  .p-body-text {
    max-width: 94%;
    margin-bottom: 2rem;

    @include tablet() {
      margin: auto;
      max-width: 50%;
    }
  }

  &__cover {
    width: 100%;
    margin: 0;

    @include tablet() {
      padding: 2.2rem;
    }

    img {
      width: 100%;

      @include tablet() {
        border-radius: $border-radius-box;
      }
    }

    &--video {
      padding: 0;

      iframe {
        width: 100%;
        min-height: 20rem;

        @include tablet() {
          min-height: 36rem;
          border-radius: $border-radius-box;
        }
      }
    }
  }

  &__procedure {
    position: relative;
    margin-top: 2rem;

    @include desktop() {
      display: flex;
      align-items: flex-start;
    }

    &--ingredients {
      background-color: $light-green;
      width: 100%;
      margin: 0;
      padding: 1rem;
      text-align: left;

      h2 {
        font-size: $font-intro;
        font-weight: 600;
        width: 100%;
        text-align: center;
        margin: 1rem;
      }

      @include tablet() {
        border-radius: $border-radius-box;
        width: 94%;
        margin: auto;
      }

      @include desktop() {
        width: 50%;
        align-self: flex-start;
        margin: 0;
      }
    }

    &--steps {
      width: 100%;
      margin: 0;
      padding: 1rem;
      text-align: left;

      h3 {
        font-size: $font-intro;
        font-weight: 600;
        color: $profile-red;
        width: 100%;
        margin: 1.2rem 0 1rem 14%;
      }

      @include desktop() {
        width: 50%;
        align-self: flex-start;
        margin: 0;
      }
    }
  }

  .recipe-lists {
    list-style: none;
    margin: 0;
    padding: 0 1rem;

    li {
      display: flex;
      border-bottom: 0.01rem solid $darkgrey;
      padding: 0.5rem 0;
      font-size: $font-small;

      &:last-child {
        border-bottom: none;
      }

      .left-side {
        min-width: 10%;
        padding: 0.5rem;
        font-weight: 600;
        color: $profile-red;

        &__wider {
          min-width: 25%;
        }

        &__darker {
          color: $black;
        }
      }

      .right-side {
        min-width: 90%;
        padding: 0.5rem;

        &__wider {
          min-width: 75%;
        }

        .tip {
          width: 100%;
          padding: 1.2rem 0.8rem;
          margin-top: 0.8rem;
          background-color: $light-orange;
          border-bottom: none;
          border-radius: $border-radius-box;
          text-align: center;
        }
      }
    }
  }
}
