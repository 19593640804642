@import "../m-variables";
@import "responsive";

// Boxes fragments
.l-box {
  width: 100%;
  margin: 1rem auto;
  padding: 0;
  min-width: 18rem;
  max-width: 18.2222rem;
  border-radius: $border-radius-box;
  color: $black;

  @include tablet() {
    min-width: 11rem;
    max-width: 15rem;
    max-height: 17rem;
    height: 100%;
    margin: 1rem;
  }

  @include desktop() {
    min-width: 19rem;
    max-width: 21rem;
    max-height: 21rem;
    margin: 0;
  }

  &__link {
    text-decoration: none;
  }

  figure {
    width: 100%;
    display: inline-block;
    padding: 0;
    margin: 0;
    z-index: -1;
    overflow: hidden;
    border-radius: $border-radius-box $border-radius-box 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      transition: transform 0.3s;
      flex-basis: 0 !important;

      @include tablet() {
        height: 9rem;
      }

      @include desktop() {
        height: 13.2rem;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &__type {
    width: 94%;
    margin: auto;
    padding: 1rem 1rem 0 1rem;
    color: $primary-color;
    font-size: $font-extraSmaller;
    text-align: center;
    text-decoration: none;

    @include tablet() {
      font-size: $font-smaller;
    }
  }

  &__title {
    width: 100%;
    padding: 0.2rem 1rem 2rem 1rem;
    margin-top: 0.4rem;
    font-size: $font-normal;
    text-align: center;
    font-style: italic;
    font-weight: 600;
    text-decoration: none;
  }

  &__orange {
    background-color: $light-orange;
  }

  &__blue {
    background-color: $light-blue;
  }

  &__green {
    background-color: $light-green;
  }

  &__grey {
    background-color: $lightgrey;
  }
}

.l-box-row {
  width: 100%;
  text-align: center;

  &__title {
    width: 100%;
    font-size: $font-title-medium;
    font-weight: 600;
  }

  &__cta {
    width: 100%;
    font-size: $font-normal;
    font-style: italic;
    text-decoration: none;
    font-weight: 600;
    color: $black;
    padding-bottom: 1rem;

    &::after {
      content: url("../images/icons/arrow-outline-round.svg");
      width: 1.222rem;
      height: 1.222rem;
      vertical-align: calc(50% - 1.222rem);
      padding-left: 0.556rem;
    }
  }

  &__items {
    display: block;
    margin-top: 1rem;

    @include tablet() {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
    }
  }
}
