@import "../m-variables";
@import "responsive";

.merchandising {
  position: relative;
  width: 100%;

  &__title {
    width: 100%;
    font-family: $font-app;
    color: $primary-color;
    font-size: $font-title;
    font-weight: 600;
    text-align: center;
    margin: auto;
    padding: 1rem 0;
  }

  h2 {
    width: 100%;
    font-family: $font-app;
    color: $black;
    font-size: $font-intro;
    font-weight: 600;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
  }

  p {
    width: 100%;
    text-align: center;
  }

  figure {
    width: 100%;
    margin: auto;
    text-align: center;

    img {
      width: 100%;
      border-radius: $border-radius-box;

      @include tablet() {
        max-width: 44.44rem;
      }
    }
  }
}
