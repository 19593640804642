@import "../m-variables";
@import "responsive";

.deviation-form {
  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }

  h3 {
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 0.8rem;
    font-weight: 600;
    padding-left: 0.8rem;
  }

  &__message {
    width: 90%;
    text-align: center;
    margin: auto;
    font-size: $font-intro;
    font-weight: 600;
  }

  @include tablet() {
    .row {
      width: calc(100% + 2rem);
      margin-left: -1rem;
      margin-right: -1rem;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  a {
    color: $primary-color;
  }

  .deviation-form-loading {
    text-align: center;
  }
}
