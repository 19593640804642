@import "../m-variables";
@import "responsive";

.navigation-grid {
  text-align: center;

  h2 {
    font-size: $h2;
    color: $primary-color;
    font-weight: 600;
    text-align: center;
    margin: 4rem 0 2rem 0;
  }

  section {
    padding-bottom: 4rem;
    margin: 4rem 0;
    border-bottom: 1px solid $border-color;
  }

  &__items {
    display: grid;
    justify-content: center;
    row-gap: 3.4rem;
    column-gap: 2.4em;
    grid-template-columns: repeat(auto-fit, 9rem);

    @include tablet() {
      justify-content: space-between;
      grid-template-columns: repeat(auto-fit, 11rem);
    }

    @include desktop() {
      justify-content: space-between;
      grid-template-columns: repeat(auto-fit, 13.2rem);
    }

    a {
      color: black;
      text-decoration: none;
      text-align: center;

      &:hover {
        color: $primary-color;
      }
    }
  }

  &__read-more {
    display: inline-block;
    margin-top: 4rem;
    font-size: $font-small;
    line-height: $line-height-small;
    color: $black;
    text-decoration: none;
    font-weight: 600;

    &:hover {
      color: $primary-color;
    }

    @include tablet() {
      font-size: $font-normal;
      line-height: $line-height-medium;
    }

    &::after {
      content: "";
      display: inline-block;
      background: url("../images/icons/arrow-outline-round.svg") no-repeat;
      background-size: cover;
      transform: rotate(90deg);
      width: 20px;
      height: 20px;
      vertical-align: middle;
      margin-left: 0.556rem;
    }
  }
}
