@import "../m-variables";

$carousel-height: 320px;
$carousel-width: 800px;

.di-carousel {
  width: 90%;
  margin: auto;
  display: block;

  @include desktop() {
    max-width: 75%;
  }

  [aria-roledescription="carousel"] {
    position: relative;
    height: 0;
    padding-top: calc($carousel-height / $carousel-width) * 100%;
    background: white;

    [aria-roledescription="slide"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: none;
      z-index: 1;
      background-color: $lightgrey;
      border-radius: $border-radius-box;
    }

    [aria-roledescription="slide"].slide__active {
      display: block;
    }

    .slide__cover {
      margin: 0;

      img {
        border-radius: $border-radius-box;
        width: 100%;
      }
    }

    .slide__center {
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }

    .arrows {
      position: absolute;
      top: 50%;
      width: 2.2rem;
      height: 2.2rem;
      margin: auto 0;
      z-index: 2;
      cursor: pointer;
      border: none;
      flex-basis: 2.2rem;

      @include tablet() {
        width: 4.2rem;
        height: 4.2rem;
        flex-basis: unset;
      }

      &[disabled] {
        opacity: 0.2;
        cursor: not-allowed;
      }

      &__previous {
        left: 0;
        background: url("../images/icons/arrow-fill-left.svg") 0 50% no-repeat padding-box;
        background-size: cover;
        transform: translate(-35%, -50%);

        @include tablet() {
          transform: translate(-50%, -50%);
        }
      }

      &__next {
        right: 0;
        background: url("../images/icons/arrow-fill-right.svg") 0 50% no-repeat padding-box;
        background-size: cover;
        transform: translate(35%, -50%);

        @include tablet() {
          transform: translate(50%, -50%);
        }
      }
    }
  }
}
