@import "../m-variables";
// buttons

.btn {
  border-radius: $border-radius-buttons;
  padding: 0.8rem 1.6rem;
  font-size: $font-small;
  font-weight: 600;
  font-family: $font-app;
  border: none;

  &__btn-primary {
    color: #fff;
    background-color: $primary-color;
    transition: all 0.3s;

    &:hover {
      color: #000;
      background-color: $secondary-color;
    }
  }
}

.btn__no-style {
  background-color: transparent;
  border-style: none;
  margin: 0;
  padding: 0;
}

/* Override for tiff */
.a-btn--default {
  color: black;
  font-style: italic;
  font-weight: 600;
  text-decoration: none;
  border-bottom: 0.2rem solid transparent;

  &:hover {
    color: $primary-color;
    border-bottom: 0.2rem solid $secondary-color;
    text-decoration: none;
  }
}

// Interactive elements
.di-accordion {
  width: 94%;
  margin: auto;

  @include desktop {
    max-width: 75%;
  }

  &-item {
    width: 100%;

    &__body {
      background-color: $lightgrey;
      padding: 1.4rem;
      margin-top: -1rem;
      font-size: $font-small;
      border-radius: 0 0 $border-radius-box $border-radius-box;
      text-align: left;
    }

    &__label {
      width: 100%;
      background-color: $light-orange;
      border-radius: $border-radius-box;
      margin: 0.5rem 0;
      padding: 1rem;
      font-size: $font-small;
      font-weight: 600;
      display: flex;
      cursor: pointer;
      outline-color: $profile-yellow;
      text-align: left;

      &::after {
        margin: 0 0.5rem 0 auto;
        transition: transform 0.3s;
        content: url("../images/icons/arrow-outline-down.svg");
        width: 1.222rem;
        height: 1.222rem;
      }

      &[aria-expanded="true"]::after {
        transform: rotate(180deg);
      }

      &:hover {
        background-color: $profile-yellow;
      }

      &--active {
        background-color: $profile-yellow;
        outline-color: transparent;
      }
    }
  }
}

.di-breadcrumbs {
  $breadcrumb-fontSize: calculate-rem(14px);
  $breadcrumb-margin: calculate-rem(7px);

  &__container {
    display: flex;
    padding: calculate-rem(10px) calculate-rem(20px);
    max-width: $max-width;
    margin: auto;
    list-style: none;

    @include tablet() {
      padding-left: calculate-rem(35px);
    }

    @include desktop() {
      padding-left: calculate-rem(50px);
    }
  }

  .breadcrumb {
    display: inline;
    font-size: $breadcrumb-fontSize;
    line-height: calculate-rem(24px);

    &::before {
      content: "/";
      margin: 0 $breadcrumb-margin;
    }

    &__link {
      text-decoration: none;
      font-size: $breadcrumb-fontSize;
    }

    &__text {
      color: $black;
      font-size: $breadcrumb-fontSize;
    }

    // first crumb
    &:first-child::before {
      margin: 0 $breadcrumb-margin 0 0;
    }

    // last crumb
    &:last-child {
      &::before {
        color: $black;
      }

      .breadcrumb__text {
        color: $darker-gray;
      }
    }
  }
}

// banners
.o-intro {
  width: 100%;
  margin-bottom: 2rem;
  height: auto;

  @include tablet() {
    margin-bottom: 8rem;
  }

  img {
    height: auto;
    width: 100%;
    max-height: 15.6rem;
    min-height: unset;

    @include tablet() {
      height: 36rem;
      max-height: unset;
    }
  }

  &__button {
    display: none;
  }

  &__content {
    margin: 0;

    @include tablet() {
      width: 100% !important; // in rder to overwrite TIFF classes
      margin: -12rem 0 0.8rem 0 !important; // in rder to overwrite TIFF classes
    }

    @include desktop() {
      padding: 0 48% 0 0;
    }
  }

  &__link-container {
    padding: 0;

    @include tablet() {
      display: block;
    }

    &:hover {
      padding: 0;
    }
  }

  &__link {
    margin: 0;
    padding: 0;

    &:hover {
      .o-intro__link-container {
        background-color: transparent;
        border: none;
        border-top: none;
        padding: 0;
      }
    }
  }

  &__link-container-orange {
    background-color: transparent;
  }

  &__heading {
    color: $primary-color;
    font-weight: 600;
    font-size: $font-title-medium;
  }

  &__preface {
    color: $black;
    font-size: $font-small;
    line-height: 1.5rem;

    @include desktop() {
      padding-right: 1rem;
    }
  }

  &__text {
    width: 100%;
    margin: -0.5rem 0 0 0;
    background-color: $light-orange;
    padding: 2rem 0;
    text-align: center;
    color: $black;
    font-size: $font-normal;
    line-height: 1.4rem;
    min-height: 12rem;
    position: relative;

    &::after {
      position: absolute;
      right: calc(50% - #{1.51rem / 2});
      content: "";
      display: inline-block;
      background-image: url("../images/icons/arrow-outline-round.svg");
      background-size: 1.51rem 1.51rem;
      width: 1.51rem;
      height: 1.51rem;
      vertical-align: middle;
      margin-top: 1rem;

      @include desktop() {
        margin: -4rem 0 0 -4%;
        right: 30px;
      }
    }

    @include tablet() {
      width: 100%;
      padding: 2rem 2rem 3rem 2rem;
    }

    @include desktop() {
      text-align: left;
      width: 100%;
      padding: 2.2rem 2.8rem;
      margin: -12rem 0 0 auto;
      max-width: 33.333rem;
      max-height: 10rem;
      min-height: unset;
      border-radius: $border-radius-box;
    }
  }
}

.m-banner {
  width: 100%;

  &__image {
    height: 11.6rem;

    img {
      min-height: unset;
      width: 100%;

      @include desktop() {
        height: 18rem;
      }
    }

    @include tablet() {
      height: 15rem;
    }

    @include desktop() {
      height: 18rem;
    }
  }

  &__title,
  .t-heading {
    text-shadow: none;
    font-weight: 600;
    color: $white;
    font-size: $font-title-medium;

    @include tablet() {
      font-size: $font-title;
    }
  }
}
