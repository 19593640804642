@font-face {
  font-family: "Tisa Sans";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/FFTisaSansRegular/font-regular.eot");
  src: url("../fonts/FFTisaSansRegular/font-regular.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/FFTisaSansRegular/font-regular.woff2") format("woff2"),
    url("../fonts/FFTisaSansRegular/font-regular.woff") format("woff"),
    url("../fonts/FFTisaSansRegular/font-regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Tisa Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/FFTisaSansMedium/font-medium.eot");
  src: url("../fonts/FFTisaSansMedium/font-medium.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/FFTisaSansMedium/font-medium.woff2") format("woff2"),
    url("../fonts/FFTisaSansMedium/font-medium.woff") format("woff"),
    url("../fonts/FFTisaSansMedium/font-medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Tisa Sans";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/FFTisaSansMediumItalic/font-mediumitalic.eot");
  src: url("../fonts/FFTisaSansMediumItalic/font-mediumitalic.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/FFTisaSansMediumItalic/font-mediumitalic.woff2") format("woff2"),
    url("../fonts/FFTisaSansMediumItalic/font-mediumitalic.woff") format("woff"),
    url("../fonts/FFTisaSansMediumItalic/font-mediumitalic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Tisa Sans";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/FFTisaSansBold/font-bold.eot");
  src: url("../fonts/FFTisaSansBold/font-bold.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/FFTisaSansBold/font-bold.woff2") format("woff2"),
    url("../fonts/FFTisaSansBold/font-bold.woff") format("woff"),
    url("../fonts/FFTisaSansBold/font-bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Tisa Sans";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/FFTisaSansBlack/font-black.eot");
  src: url("../fonts/FFTisaSansBlack/font-black.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/FFTisaSansBlack/font-black.woff2") format("woff2"),
    url("../fonts/FFTisaSansBlack/font-black.woff") format("woff"),
    url("../fonts/FFTisaSansBlack/font-black.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Tisa Sans";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/FFTisaSansExtraBold/font-extrabold.eot");
  src: url("../fonts/FFTisaSansExtraBold/font-extrabold.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/FFTisaSansExtraBold/font-extrabold.woff2") format("woff2"),
    url("../fonts/FFTisaSansExtraBold/font-extrabold.woff") format("woff"),
    url("../fonts/FFTisaSansExtraBold/font-extrabold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Tisa Sans";
  font-style: italic;
  font-weight: 800;
  src: url("../fonts/FFTisaSansBlackItalic/font-blackitalic.eot");
  src: url("../fonts/FFTisaSansBlackItalic/font-blackitalic.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/FFTisaSansBlackItalic/font-blackitalic.woff2") format("woff2"),
    url("../fonts/FFTisaSansBlackItalic/font-blackitalic.woff") format("woff"),
    url("../fonts/FFTisaSansBlackItalic/font-blackitalic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Tisa Sans";
  font-style: italic;
  font-weight: 900;
  src: url("../fonts/FFTisaSansExtraBoldItalic/font-extrabolditalic.eot");
  src: url("../fonts/FFTisaSansExtraBoldItalic/font-extrabolditalic.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/FFTisaSansExtraBoldItalic/font-extrabolditalic.woff2") format("woff2"),
    url("../fonts/FFTisaSansExtraBoldItalic/font-extrabolditalic.woff") format("woff"),
    url("../fonts/FFTisaSansExtraBoldItalic/font-extrabolditalic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Tisa Sans";
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/FFTisaSansRegularItalic/font-regularitalic.eot");
  src: url("../fonts/FFTisaSansRegularItalic/font-regularitalic.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/FFTisaSansRegularItalic/font-regularitalic.woff2") format("woff2"),
    url("../fonts/FFTisaSansRegularItalic/font-regularitalic.woff") format("woff"),
    url("../fonts/FFTisaSansRegularItalic/font-regularitalic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Tisa Sans";
  font-style: italic;
  font-weight: bold;
  src: url("../fonts/FFTisaSansBoldItalic/font-bolditalic.eot");
  src: url("../fonts/FFTisaSansBoldItalic/font-bolditalic.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/FFTisaSansBoldItalic/font-bolditalic.woff2") format("woff2"),
    url("../fonts/FFTisaSansBoldItalic/font-bolditalic.woff") format("woff"),
    url("../fonts/FFTisaSansBoldItalic/font-bolditalic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Tisa Sans";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/FFTisaSansThin/font-thin.eot");
  src: url("../fonts/FFTisaSansThin/font-thin.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/FFTisaSansThin/font-thin.woff2") format("woff2"),
    url("../fonts/FFTisaSansThin/font-thin.woff") format("woff"),
    url("../fonts/FFTisaSansThin/font-thin.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Tisa Sans";
  font-style: italic;
  font-weight: 200;
  src: url("../fonts/FFTisaSansThinItalic/font-thinitalic.eot");
  src: url("../fonts/FFTisaSansThinItalic/font-thinitalic.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/FFTisaSansThinItalic/font-thinitalic.woff2") format("woff2"),
    url("../fonts/FFTisaSansThinItalic/font-thinitalic.woff") format("woff"),
    url("../fonts/FFTisaSansThinItalic/font-thinitalic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Tisa Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/FFTisaSansLightItalic/font-lightitalic.eot");
  src: url("../fonts/FFTisaSansLightItalic/font-lightitalic.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/FFTisaSansLightItalic/font-lightitalic.woff2") format("woff2"),
    url("../fonts/FFTisaSansLightItalic/font-lightitalic.woff") format("woff"),
    url("../fonts/FFTisaSansLightItalic/font-lightitalic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Tisa Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/FFTisaSansLight/font-light.eot");
  src: url("../fonts/FFTisaSansLight/font-light.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/FFTisaSansLight/font-light.woff2") format("woff2"),
    url("../fonts/FFTisaSansLight/font-light.woff") format("woff"),
    url("../fonts/FFTisaSansLight/font-light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
    font-family: 'Nordvest';
    src: url('../fonts/Nordvest/Nordvest-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Nordvest';
    src: url('../fonts/Nordvest/Nordvest-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}
