@import "../m-variables";
@import "responsive";

.faqs {
  text-align: center;

  h1 {
    font-family: $font-app;
    color: $primary-color;
    font-size: $font-title;
    font-weight: 600;
    max-width: 90%;
    text-align: center;
    margin: auto;
    padding-top: 4rem;

    @include tablet() {
      max-width: 75%;
    }
  }

  &__preface {
    font-size: $font-intro;
    margin: 2rem auto 4rem auto;
    max-width: 90%;

    @include tablet() {
      max-width: 60%;
    }
  }

  &__section {
    margin-top: 3rem;
    margin-bottom: 2rem;

    h2 {
      margin: 2rem 0;
    }
  }
}
