@mixin animation-bouncing($name) {
  @keyframes #{$name} {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.3);
    }

    100% {
      transform: scale(1.2);
    }
  }
}
