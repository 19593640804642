.error {
  margin: 1rem 1rem 0 1rem;

  &:last-child {
    margin-bottom: 1rem;
  }

  &__part {
    border: 2px dashed red;
    padding: 15px;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}
