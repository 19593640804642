/* stylelint-disable at-rule-no-unknown */

// Utils: remcalculator
@function calculate-rem($size, $base-size: 18px) {
  $remSize: calc($size / $base-size);

  @return #{$remSize}rem;
}

/* stylelint-enable at-rule-no-unknown */
// // logo dimentions; height & width
// $logo-size-big: 80px 250px;
// $logo-size-normal: 70px 225px;
// $logo-size-small: 60px 200px;

// logo - header
$logo-header: url('../images/logo.svg');
// $logo-header-size-desktop: $logo-size-big;
// $logo-header-size-tablet: $logo-size-normal;
// $logo-header-size-mobile: $logo-size-small;

// logo - footer
$logo-footer: url('../images/logo-footer.svg');
// $logo-footer-size: $logo-size-big;

// misc
$golden-ratio: 1.6;

// Layout
$site-max-width: 1200px;
$max-width: calculate-rem($site-max-width);
$max-width-narrow: calculate-rem($site-max-width/$golden-ratio);

// Colors
$profile-red: #d50037;
$profile-yellow: #ffd500;
$profile-cream: #fffaf0;
$primary-color: #d50037;
$secondary-color: #ffd500;

$black: #000;
$white: #fff;
$grey: #ccc;
$lightgrey: #f2f2f2;
$darkgrey: #aaa;
$darker-gray: #7f7f7f; // black 50% opacity
$light-orange: #fff0d5;
$light-green: #e4f0c8;
$light-blue: #d8f2f4;

// header
$background-color: #fff;
$header-menu-color-secondary: #fbba00;
$header-menu-search-background: #fff;
$menu-rollover: #d50037;
$border-color: #ccd2de;
$black: #000;
$white: #fff;

// Font-size
$font-app: "Tisa Sans", sans-serif;
$font-normal: calculate-rem(20px);
$font-title: calculate-rem(50px);
$font-title-medium: calculate-rem(30px);
$font-intro: calculate-rem(24px);
$font-body: calculate-rem(18px);
$font-small: calculate-rem(16px);
$font-smaller: calculate-rem(14px);
$font-extraSmaller: calculate-rem(12px);

$line-height-big: calculate-rem(42px);
$line-height-medium: calculate-rem(30px);
$line-height-normal: calculate-rem(26px);
$line-height-small: calculate-rem(24px);
$line-height-smaller: calculate-rem(20px);

$h1: $font-title;
$h2: $font-title-medium;
$h3: $font-intro;
$h4: $font-normal;
$h5: $font-body;

$border-radius-box: calculate-rem(10px);
$border-radius-boxCard: calculate-rem(20px);
$border-radius-buttons: calculate-rem(60px);
$input-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
$input-border-normal: 1px solid #ccd2de;

// Overrides for "~xp-lib-brand/css/variables.scss"
$title-color: $primary-color;
$text-color: black;
$link-color: $primary-color;
$error-color: $secondary-color;
$link-text-decoration: none;

$link-color-hover: $link-color;
$link-text-decoration-hover: underline;

$body-background-color: transparent;
$body-background: transparent;

$primary-background-color: $profile-cream;
$secondary-background-color: white;

$base-font-size: 18px;
$base-line-height: 1.4;

// TINE variables - used in some common parts
$tine-primary-background-color: #fff;
$tine-default-border-color: $lightgrey;
$tine-default-border-style: solid;
$tine-default-border-width: 1px;
$tine-default-border: none;//$tine-default-border-width $tine-default-border-style $tine-default-border-color;

// Layout
$column-gutter: $base-line-height * $base-font-size;
$container-max-width: $max-width;

// Font
$base-font-family: $font-app;

// Parts
$content--title-align: center;
$content--author-font-size: 0.8rem;

$grid-brand--background: $primary-background-color;
$grid-brand--item-width-mobile: calc(100% / 4);
$grid-brand--item-width-tablet: calc(100% / 6);
$grid-brand--item-width-desktop: calc(100% / 9);

$html-content--link-text-decoration: underline;
$html-content--link-text-decoration-hover: underline;
$html-content--link-text-color: $primary-color;

$button--background: $secondary-background-color;
$button--text-color: $text-color;

// Form input
$input-border-width: 0;
$input-border: 0;

$z-indexs: (
  modal: 200,
  submenu: 20,
  history-line: 100,
  product-box-figure: 4,
  drop-list-nav: 1000,
  logo-link: 100,
  header-menu: 1020,
  mobile-overlay: -8,
  search-box: 10,
  header-container: 2,
);
