@import "../m-variables";
@import "responsive";

.employees {
  display: flex;
  margin: 2rem auto;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 2rem;

  &__picture {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 0.02rem solid $white;
  }

  &__single {
    display: flex;
    padding: 3rem;
    width: 100%;
    margin: auto;
    max-width: 95%;
    background-color: $lightgrey;
    border-radius: $border-radius-box;
    flex-wrap: wrap;

    @include desktop() {
      max-width: 55%;
    }

    &--details {
      width: 100%;
      text-align: left;
      font-size: $font-small;

      .details__name {
        text-align: center;
        color: $primary-color;
        font-weight: 600;
        font-size: $font-small;
        margin: 0.6rem 0 0.2rem 0;

        @include desktop() {
          text-align: left;
        }
      }

      .details__title {
        text-align: center;
        font-size: $font-small;
        margin: 0;

        @include desktop() {
          text-align: left;
        }
      }

      @include desktop() {
        width: 50%;
      }
    }

    &--contact {
      width: 100%;
      font-size: $font-smaller;
      margin-top: 1rem;
      text-align: center;

      p {
        margin: 0;
      }

      @include desktop() {
        text-align: left;
      }
    }
  }

  &__person {
    width: 100%;
    height: 14rem;
    text-align: center;
    margin: 2rem 2rem;
    justify-self: center;

    @include tablet() {
      width: 14rem;
    }

    &--figure {
      width: 9rem;
      height: 9rem;
      margin: auto;
    }

    &--name {
      text-align: center;
      color: $primary-color;
      font-weight: 600;
      font-size: $font-small;
      margin: 0.6rem 0 0.2rem 0;
    }

    &--title {
      text-align: center;
      font-size: $font-small;
      margin: 0;
    }
  }
}
