@import "../m-variables";

.vacancy-list {
  overflow-x: auto;

  tbody td:first-child {
    padding: 0;
  }

  a {
    color: black;
    text-decoration: none;
    padding: 1rem 2rem;
    display: block;
    width: 100%;

    @include tablet() {
      &::before {
        content: '';
        display: inline-block;
        background: url("../images/icons/external-link.svg") 0 50% no-repeat padding-box;
        background-size: contain;
        height: 20px;
        width: 20px;
        margin-right: 1.2rem;
      }
    }
  }
}
