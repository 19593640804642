@import "../m-variables";

$logo-size-footer: 7.5rem;

.footer-container {
  position: relative;
  width: 100%;
  margin-top: 1.6rem;
  z-index: 0;
}

.di-footer {
  color: #000;
  font-size: $font-small;
  line-height: 1.75;
  margin: 0 auto;
  position: relative;
  width: 100%;
  overflow: hidden;
  max-width: $max-width;
  background: $profile-yellow;

  &__half-circle {
    width: 120%;
    height: 500px;
    background: $profile-cream;
    border-radius: 0 0 50% 50%;
    position: absolute;
    left: -10%;
    top: -24rem;
  }

  &__logo {
    background: url("../images/static/diplom-is-logo-footer.png") 50% 50% no-repeat padding-box;
    background-size: contain;
    height: $logo-size-footer;
    width: $logo-size-footer;
    position: absolute;
    top: 0.25rem;
    left: calc(50% - (#{$logo-size-footer} / 2));
  }

  &__content-container {
    display: flex;
    align-items: flex-start;
    align-content: stretch;
    flex-wrap: wrap;
    padding-top: 8rem;

    section[class^="box--"] {
      color: $black;
      width: 100%;
      padding: 2rem;
      text-align: center;

      @include desktop {
        width: calc(100% / 3);
      }
    }

    @include desktop {
      .box--1,
      .box--3 {
        text-align: left !important;
      }

      .box--3 {
        padding-left: 8rem !important;
      }
    }

    .heading {
      color: #000;
      margin: 1.2rem 0;

      @include tablet() {
        min-width: 7rem;
      }
    }

    .text {
      font-size: $font-small;
      padding: 0;
      margin-bottom: 1.2rem;

      a {
        font-size: $font-small;
        font-weight: normal;
        text-decoration: none;
      }

      p {
        margin: 0 0 1.2rem;

        a {
          font-weight: 600;
          color: $primary-color;
          font-style: italic;
        }
      }
    }

    .link {
      color: #000;
      font-size: $font-small;
      font-style: italic;
      font-weight: 600;
      text-decoration: none;
      padding-bottom: 0.5rem;
      margin-bottom: 1.2rem;

      span {
        padding-right: 0.5rem;

        &::after {
          position: absolute;
          margin: 0 0 0 0.4rem;
          width: 1.6rem;
          height: 1.6rem;
          content: "";
          background: url("../images/icons/arrow-outline-round.svg") 50% 50% no-repeat padding-box;
        }
      }

      &:hover {
        color: $menu-rollover;
      }
    }

    hr {
      border: 0.1rem solid $profile-red;
      width: 50%;
      margin: 1rem auto;

      @include desktop {
        display: none;
      }

      &:last-child {
        display: none;
      }
    }
  }

  &__shortcuts-row {
    width: 100%;
    text-align: center;

    .shortcut-link {
      font-family: $font-app;
      text-decoration: none;

      &__main {
        font-weight: 600;
        font-size: $font-normal;
      }
    }
  }

  &__component {
    flex-direction: column;
    padding: 0.2rem 0.6rem;
    width: 100%;
    font-family: $font-app;
    font-size: $font-small;
    text-align: center;

    @include desktop() {
      width: 33%;
    }
  }

  &__component-content {
    font-size: $font-small;
    padding: 0;

    p {
      margin: 0;
      min-height: 4.6rem;

      a {
        font-size: $font-small;
        font-weight: 600;
        text-decoration: none;
        color: $primary-color;
        font-style: italic;
      }
    }

    a {
      font-size: $font-small;
      font-weight: normal;
      text-decoration: none;
    }
  }

  &__shortcuts {
    width: 100%;
    background-color: $profile-yellow;
    text-decoration: none;

    .footer-menu {
      margin: 2rem 0;

      @include desktop {
        padding-bottom: 4rem;
      }
    }

    ul {
      padding-left: 0;
      padding-bottom: 1rem;
      padding-top: 1rem;
      margin: 0;
      margin-bottom: 1rem;

      @include desktop() {
        width: 100%;
        margin-bottom: -3rem;
      }

      &:first-child {
        border-bottom: 0.2rem;
        padding-top: 0;

        @include desktop() {
          border-bottom: none;
          padding-top: 1.4rem;
        }
      }

      @include desktop() {
        display: inline-block;
        padding-bottom: 0;
        margin: 0;

        &:last-child {
          float: right;
          padding-top: 0.5rem;
        }
      }
    }

    li {
      display: inline-block;
      margin-bottom: 1rem / 2;
      margin-right: 1rem;

      a {
        color: #000;
        font-size: $font-small;
        text-decoration: none;

        &:hover {
          color: $menu-rollover;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
