@import "../m-variables";
@import "responsive";

.list-searchresults {
  max-width: $max-width;
  margin: 4rem auto auto;

  .o-search-page {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .o-search-page--form {
    position: relative;
    max-width: 28rem;
    margin-left: auto;
    margin-right: auto;

    input[type="search"] {
      width: 100%;
      background-color: transparent;
      box-shadow: $input-shadow;
      border: 0;
      padding: 0.7rem 3rem 0.7rem 2rem;
      border-radius: $border-radius-box;
    }

    [type="submit"] {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: transparent;
      border: 0;
      padding-right: 1rem;
      padding-left: 1rem;
    }

    .a-icon-search::after {
      content: "";
      background: url("../images/icons/search-icon-red.svg") 0 50% no-repeat padding-box;
      display: block;
      height: 20px;
      width: 20px;
    }
  }

  .o-search-page--text {
    text-align: center;

    &__intro {
      font-weight: 600;
      margin-bottom: 1rem;
    }

    &__result {
      font-weight: 600;
      margin-top: 1rem;
    }

    &__query {
      color: $primary-color;
    }

    &__result-list {
      margin-top: 3rem;

      a {
        font-style: italic;
        font-weight: 600;
        color: black;
        text-decoration: none;
        border-bottom: 0.2rem solid transparent;
        white-space: nowrap;

        &:hover {
          color: $primary-color;
          border-bottom: 0.2rem solid $secondary-color;
          text-decoration: none;
        }

        &:not(:first-child) {
          margin-left: 1.5rem;
        }
      }
    }
  }

  .o-collection {
    &.o-collection--search {
      list-style: none;
      padding-left: 0;

      > :first-child .m-card__link {
        border-top: 1px solid $lightgrey;
      }
    }

    &--heading {
      text-align: center;
      font-size: $font-intro;
      margin: 6rem 0 2rem 0;
      font-weight: 600;
    }
  }

  .m-card {
    margin: 0;

    @include tablet() {
      &.m-card--no-media .m-card__link {
        padding-left: calc(230px + 2rem);
      }
    }

    .m-card__link {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $lightgrey;
    }

    &:hover .a-btn--default {
      border-bottom: 0;

      .a-btn-text {
        color: #d50037;
        border-bottom: 0.2rem solid #ffd500;
        text-decoration: none;
      }
    }

    .m-card__heading {
      font-weight: 600;
      font-size: $font-intro;
    }

    .m-card__preface {
      padding-bottom: inherit;
      margin: 1rem 0;
      max-width: 70ch;
    }

    .m-card__inner {
      display: flex;
    }

    .m-card__image {
      padding-right: 2rem;

      img {
        border-radius: $border-radius-box;
        width: auto;
        background-color: $lightgrey;
      }

      @include mobile() {
        display: none;
      }
    }

    &__content {
      background-color: transparent;
      padding: 0;
      min-height: initial;

      @include tablet() {
        padding-left: 2rem;
      }
    }

    &__read-more-btn {
      position: initial;
      display: inline;
      padding: 0;

      $iconSize: calculate-rem(23px);

      &::before {
        content: "";
        display: inline-block;
        background-image: url("../images/icons/arrow-outline-round.svg");
        background-size: $iconSize $iconSize;
        width: $iconSize;
        height: $iconSize;
        vertical-align: middle;
        margin-right: 0.556rem;
      }
    }
  }
}
