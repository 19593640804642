@import "../m-variables";

table.rowed-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.5rem;

  caption {
    font-size: $h2;
    font-weight: 600;
  }

  thead th {
    text-align: left;
    font-weight: normal;

    &.text-right {
      text-align: right;
    }
  }

  tbody tr {
    background-color: $light-orange;
    font-weight: 600;

    &:hover {
      background-color: $profile-yellow;
    }

    td:first-child {
      border-top-left-radius: $border-radius-box;
      border-bottom-left-radius: $border-radius-box;
    }

    td:last-child {
      border-bottom-right-radius: $border-radius-box;
      border-top-right-radius: $border-radius-box;
    }
  }

  td,
  th {
    font-weight: normal;
    font-size: $font-small;
    padding: 1rem 0.5rem;

    @include tablet() {
      font-size: $font-normal;
    }

    &:first-child {
      padding-left: 2rem;
    }

    &:last-child {
      padding-right: 2rem;
    }
  }
}
