@import "../m-variables";
@import "responsive";
@import "../mixins";

$size-circle-main: 20em;
$size-circle-support: 10.5em;

@mixin bouncyStyle {
  transform: scale(1.2);
  animation-name: bounce;
  animation-duration: 0.3s;
  animation-timing-function: ease;
}

.di-product {
  margin: auto;
  padding: 4rem 0;
  max-width: 66.6666rem;

  .product-title {
    font-family: $font-app;
    color: $primary-color;
    font-size: $font-title;
    font-weight: 600;
    max-width: 90%;
    text-align: center;
    margin: auto;
    padding-top: 4rem;

    @include tablet() {
      max-width: 75%;
    }
  }

  h2 {
    width: 100%;
    text-align: center;
    margin: 4rem 0 2rem 0;
  }

  .p-intro-text {
    margin: 2rem 1rem 3rem 1rem;

    p {
      text-align: center;
      font-size: $font-intro;
    }

    @include tablet() {
      margin: 2rem 0;
    }
  }

  .cover {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 0 1.5rem;
    position: relative;

    &__circle {
      position: absolute;
      top: 0;
      border-radius: 50%;
      background: $white;
      transition: background 150ms ease-in;

      &--main {
        width: $size-circle-main;
        height: $size-circle-main;
        left: calc(50% - #{$size-circle-main} / 2);
      }
  
      &--support {
        width: $size-circle-support;
        height: $size-circle-support;
        left: calc(50% - #{$size-circle-support} / 2);
      }
    }

    .product-browsing {
      display: none;
      max-width: 25%;
      width: 12rem;
      height: 14rem;
      margin: auto 0;
      text-align: center;
      position: relative;

      &:hover { // support hover
        .cover__circle {
          background: $profile-yellow;
        }

        img {
          @include bouncyStyle;
        }
      }

      a {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        position: relative;
      }

      @include tablet() {
        display: block;
      }

      img {
        width: auto;
        max-height: 100%;
        max-width: 100%;
        height: auto;
        transition: all 0.3s;
      }

      .product-name {
        text-align: center;
        width: 100%;
        font-size: $font-small;
        font-style: italic;
        font-weight: 600;
        margin-top: 1rem;
      }
    }

    .main-product__img {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 24rem;
      height: 28rem;
      margin: 0;

      &:hover img { // main hover
        @include bouncyStyle;
      }

      @include tablet() {
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;
      }

      img {
        width: auto;
        max-height: 100%;
        max-width: 100%;
        height: auto;
        transition: all 0.3s;
        z-index: 1;
      }
    }
  }

  &__description {
    width: 90%;
    margin: 0.4rem auto;
    background-color: $lightgrey;
    border-radius: $border-radius-box;
    display: block;
    padding: 1.6rem;

    @include tablet() {
      display: flex;
    }

    &--features {
      width: 100%;
      font-size: $font-smaller;
      text-align: center;

      h4 {
        font-size: $font-smaller;
        font-weight: 600;
      }

      @include tablet() {
        width: 50%;
        padding: 0 1rem 1.2rem 1rem;
      }
    }

    @include desktop() {
      max-width: 75%;
    }
  }
}

@include animation-bouncing(bounce);
