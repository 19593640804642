@import "@tine/tiff/dist/_variables.scss";
@import "@tine/tiff/dist/_mq.scss";

body {
  max-width: $max-width;
  margin: 0 auto;
  background: $profile-cream;
}

// utilities
.visually-hidden,
.visually-hidden-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.di-hp-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -2;
}

.visually-hidden-focusable:focus {
  position: absolute;
  color: black;
  z-index: 3;
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

// layouts
.main-container {
  background-color: #fff;
  padding-bottom: 2rem;
}

.scrollable {
  overflow-x: auto;

  &:hover {
    cursor: pointer;
  }

  table {
    border: 0;
    border-collapse: collapse;

    tr:nth-child(even) {
      background: $light-blue;
    }

    th {
      font-weight: bold;
      padding: 0.2rem;
      border: 0;
    }

    td {
      font-weight: normal;
      padding: 0.2rem;
      border: 0;
    }
  }
}

@include tablet() {
  h1 {
    font-size: 1.6rem;
  }

  .margin-vertical-medium {
    margin: 2rem 0;
  }
}

// Text

.text-centerfy {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-intro {
  font-size: $font-intro;
}

.text-body {
  font-size: $font-body;
}

.tabular-nums {
  font-variant-numeric: tabular-nums;
}

// positioning

.centered {
  margin: auto;
}

// Spacing

.p-1 {
  padding: 1rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pl-3 {
  padding-left: 3rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.-pl-1 {
  margin-left: -2rem;
}

.-pl-2 {
  margin-left: -2rem;
}

// Borders

.no-borders {
  border-style: none;
}

// Dimensions

.w-full {
  width: 100%;
}

.w-auto {
  width: auto;
}

// Effects

.fade-in {
  opacity: 1;
  animation-name: fade-visible;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

@keyframes fade-visible {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-out {
  opacity: 0;
  animation-name: fade-hidden;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

@keyframes fade-hidden {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.t-preface {
  color: black;
}

a {
  color: $black;

  &:hover {
    color: $primary-color;
  }

  &:visited {
    color: $primary-color;
    border-bottom: 0.2rem solid $secondary-color;
  }
}
