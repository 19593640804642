@import "../m-variables";
@import "responsive";

.header-container {
  background: $profile-yellow;
  z-index: map-get($z-indexs, header-container);
}

// Styling for mobile
.mobile--overlay {
  position: fixed;
  top: 10rem;
  left: 0;
  z-index: map-get($z-indexs, mobile-overlay);
  width: 100%;
  height: 100vh;
  background-color: rgba($color: $black, $alpha: 0.3);

  @include tablet() {
    top: 11rem;
  }
}

.di-header {
  position: relative;
  max-width: $max-width;
  margin: auto;
  z-index: 3;

  .search-btn-toggle {
    cursor: pointer;
    background: transparent;
    border: none;

    @include tablet() {
      display: none;
    }
  }

  .a-icon {
    margin: auto;
    font-size: 1.3rem;
  }

  &-main {
    position: relative;
    padding: 0.2rem;

    &__hamburger {
      float: right;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000;
      width: 2.5rem;
      padding: 1rem 1rem 1rem 1rem;
      border: none;
      margin: 2.4rem 2rem 0 0;
      background: url("../images/icons/hamburger-icon.svg") 50% 50% no-repeat
        padding-box;

      &-expanded {
        background: url("../images/icons/close.svg") 0% 50% no-repeat
          padding-box;
      }
    }

    .search-button {
      cursor: pointer;
      float: left;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000;
      width: 2rem;
      padding: 1rem;
      border: none;
      margin: 2.4rem 0 0 2.4rem;
      background: transparent url("../images/icons/search-icon.svg") 0% 50%
        no-repeat padding-box;

      &_w-icon {
        width: 2.5rem;
        border: none;
      }
    }

    &__logo {
      display: flex;
      justify-content: center;
      width: min-content;
      margin: auto;
      z-index: map-get($z-indexs, logo-link);
    }

    &__cross {
      color: #333;
    }
  }

  &__logo-link {
    background: transparent url("../images/static/diplom-is-logo.png") 50% 50% no-repeat
      padding-box;
    height: 8.4rem;
    width: 9.4rem;
    display: block;
    background-size: contain;
  }

  .di-search {
    position: absolute;
    z-index: map-get($z-indexs, search-box);
    width: 100%;
    opacity: 1;
    height: calc(100vh - 45px);
    transition: all 0.3s;

    &__container {
      position: relative;
      background-color: $white;
      z-index: 4;
      padding: 1.4rem;
    }

    &__form {
      background-color: transparent;
      position: relative;

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #000;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #000;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #000;
      }
    }

    &__button {
      padding-right: 0;

      @include tablet() {
        padding: 1rem;
      }
    }

    &__input {
      width: 100%;
    }

    &__input:focus::placeholder {
      color: transparent;
    }

    .a-icon-search {
      position: absolute;
    }

    .a-btn--primary {
      width: 100%;
    }
  }

  .di-header-menu {
    position: absolute;
    z-index: map-get($z-indexs, header-menu);
    width: 100%;
    padding: 1rem 2rem 1rem 1.4rem;
    background: #fff 0 0 no-repeat padding-box;
    max-width: 66rem;
    opacity: 1;
    height: auto;

    &__container {
      display: flex;
      justify-content: space-between;
    }

    &__menu-list {
      margin: 1.5rem 0;
    }

    &__menu-item {
      list-style: none;
      margin: 0 1.6rem 0.5rem 0;
    }

    &__menu-link {
      text-decoration: none;
      font-family: $font-app;
      color: $black;
      font-size: $font-normal;
      font-weight: 600;
      margin: 0;
      border-bottom: 0.2rem solid transparent;

      &:hover {
        color: $menu-rollover;
      }

      &[aria-current="page"],
      &--active {
        color: $menu-rollover;
        border-bottom: 0.2rem solid $secondary-color;
      }

      &--support {
        font-size: 1rem;
        font-weight: normal;
        color: #000;
      }
    }

    &__support {
      text-align: right;
    }

    &__main {
      padding-left: 0.5rem;

      .o-header-menu__menu-item {
        padding-bottom: 0.5rem;
      }
    }
  }

  .displayMenu-false {
    display: none;
  }
}

// Styling for tablet
@include tablet() {
  .di-header {
    &__logo-link {
      height: 9rem;
      width: 10rem;
      background-size: contain;
    }

    &-main {
      padding: 1rem;
    }

    .di-search {
      background-color: #fff;
      width: 40%;
      border: none;
      box-shadow: 0 0.1rem 0.3rem #ccc;
      margin-top: -0.18rem;
      margin-left: 1rem;
      height: auto;
      border-radius: $border-radius-box;

      &__container {
        border: none;
      }
    }

    .di-header-menu {
      width: 100%;
      float: right;

      &__container {
        padding: 0.5rem;
      }

      &__menu-list {
        margin: 0;
      }

      &__support {
        margin: 0;
      }
    }
  }

  .di-search::before {
    content: "";
    background-color: transparent;
    position: absolute;
    bottom: 100%;
    left: 1rem;
    border-left: 0.6rem solid transparent;
    border-right: 0.6rem solid transparent;
    border-bottom: 1.1rem solid #fff;
  }
}

// Styling for desktop
@include desktop() {
  // When on root
  .di-header__expanded {
    display: flex;
    flex-direction: column-reverse;

    .di-header__logo-link {
      height: 9.4rem;
      width: 10.4rem;
      background-size: contain;
      z-index: map-get($z-indexs, logo-link);
    }

    .di-header-main {
      padding: 0;

      &__logo {
        padding: 0;
      }

      &__search-button {
        cursor: pointer;
        width: 4rem;
        text-align: right;
        background: url("../images/icons/search-icon-red.svg") 0% 20%
          no-repeat padding-box;

        @include tablet() {
          display: none;
        }

        @include desktop() {
          display: block;
        }

        &_w-icon {
          width: 1.8rem;
          border: none;
        }
      }

      &__hamburger {
        display: none;
      }
    }

    .di-search {
      top: 100%;
      left: 0;
      width: 30%;
      margin-top: -2.4rem;
    }

    .di-search::before {
      left: 1.8rem;
      right: auto;
    }

    .di-header-menu {
      display: contents;

      &__container {
        display: contents;
      }

      &__menu-item {
        margin-bottom: 0;

        [aria-current="page"] {
          color: $menu-rollover;
          border-bottom: 0.2rem solid $secondary-color;
        }
      }

      &__main {
        display: contents;
      }

      &__menu-list {
        display: flex;
        position: absolute;
        top: 50%;
        padding-right: 1rem;
        width: 100%;
        right: 0;

        ::marker {
          display: none;
        }

        li {
          list-style: none;
        }

        :nth-child(3) {
          margin-right: auto;
        }

        :nth-child(4) {
          margin-left: auto;
        }
      }

      &__menu-link {
        padding-left: 0.4rem;
      }

      &__support {
        background-color: $primary-background-color;
        display: flex;
        width: 100%;
        margin: 0;
        padding: 0.5rem 1rem;
        font-size: $font-smaller;

        li {
          text-align: right;
          padding: 0 0 0 0.8rem;
        }

        > :first-child {
          a {
            padding-left: 0.5rem;
          }
        }
      }

      &__menu-link--support {
        color: #000;
        padding-left: 0.2rem;
        font-size: $font-smaller;

        &:hover {
          color: $menu-rollover;
        }
      }
    }
  }

  // When not on root
  .di-header {
    .di-search {
      width: 20rem;
    }
  }
}
