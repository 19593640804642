@import "../m-variables";
@import "responsive";

.submenu {
  width: 100%;
  margin: auto;
  z-index: map-get($z-indexs, submenu);
  max-width: $max-width;

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding-inline-start: 0;

    &--item {
      font-size: $font-smaller;
      color: $black;
      font-style: italic;
      padding: 0 1rem;
      font-weight: 600;

      @include tablet() {
        font-size: $font-normal;
      }

      [aria-current="page"] {
        color: $primary-color;
        border-bottom: 0.2rem solid $secondary-color;
      }

      a {
        color: $black;
        text-decoration: none;
        border-bottom: 0.2rem solid transparent;
        line-height: 2.4rem;

        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}
