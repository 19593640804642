/* stylelint-disable */

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  display: block;
}

body {
  height: 100%;
  font-family: $base-font-family, Helvetica, Arial, Sans-serif;
}

body {
  background: $primary-background-color;
  color: $text-color;
  display: flex;
  flex-direction: column;
  font-family: $base-font-family, Helvetica, Arial, Sans-serif;
  font-size: $base-font-size;
  line-height: $base-line-height;
  margin: 0;
  padding: 0;

  &.open {
    overflow: hidden;
  }

  &.norskmelk {
    background: #ffffff;

    .main-container {
      background: #f3eee8;
    }
  }
}

input,
textarea,
select,
button {
  font-size: $base-font-size;
}

.main-container {
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  max-width: $container-max-width;
  align-self: center;
  position: relative;
  flex-direction: column;
  background-color: #fff;

  // All parts should have this class and follow one dimensional
  // margin declaration (https://csswizardry.com/2012/06/single-direction-margin-declarations/)
  .part {
    margin-bottom: calc(#{$column-gutter} / 2);
    overflow: hidden; // Prevents large empty spaces in IE

    // If following a expanded part, that doesnt have margin add it to top
    &.layout--expanded + & {
      margin-top: calc(#{$column-gutter} / 2);
    }

    &--margin-negative {
      margin-bottom: calc(#{-$column-gutter} / 2);
    }

    &--margin-negative {
      margin-bottom: calc(#{-$column-gutter} / 2);
    }
  }

  .part--no-margin-bottom {
    margin-bottom: 0;
  }

  .preface {
    font-size: 1.2em;
  }
}

a,
button {
  touch-action: manipulation;
}

input {
  font-size: 1em;
  touch-action: manipulation; // Remove the 300ms delay.
  margin: 0;

  &[type="search"] {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }
  }

  &[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &[type="checkbox"],
  [type="radio"] {
    cursor: pointer;
  }
}

.aria__hide-text {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
