@import "@tine/tiff/dist/_variables.scss";
@import "@tine/tiff/dist/_mq.scss";
@import "m-variables";

.wrapper_max-width {
  width: auto;
  padding: 1rem;

  @include tablet() {
    width: auto;
    margin: auto;
  }

  @include desktop() {
    max-width: $max-width;
    margin: auto;
    padding: 0;
  }
}

.wrapper_max-width-narrow {
  max-width: $max-width-narrow;
  margin: auto;
}

.layout_max-width {
  width: 80%;
  max-width: $max-width-narrow;
  margin: auto;

  @include tablet() {
    width: 100%;
    padding: 1rem;
  }

  p {
    font-size: $font-small;

    @include tablet() {
      font-size: $font-body;
    }
  }
}

.banner-404 {
  width: 100%;

  img {
    width: 100%;
    min-height: 10rem;
  }

  @include desktop() {
    min-height: unset;
  }
}

.top-region {
  margin: 0;
  padding: 0;
  width: 100%;
}

.layout-container {
  .row {
    max-width: $container-max-width;
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 30px;
    padding-bottom: 40px;
    flex-direction: column;

    .col {
      width: 100%;

      &.col-6 {
        width: 100%; /* for small screens, it should be full width */
      }

      &.width-80 {
        width: 100%; /* for small screens, it should be full width */
      }
    }

    &.no-gutters {
      margin-right: 0;
      margin-left: 0;

      & > .col {
        padding-left: 0;
        padding-right: 0;
      }
    }

    @include tablet() {
      flex-direction: row;

      .col {
        padding: 0 1rem;
        width: 50%;

        &.col-12 {
          width: 100%;
        }

        &.col-10 {
          width: 83.3333%;
        }

        &.col-6 {
          width: 50%;
        }

        &.width-80 {
          width: 80%;
        }
      }
    }

    @include desktop() {
      .col {
        &.col-12 {
          width: 100%;
        }

        &.col-10 {
          width: 83.3333%;
        }

        &.col-8 {
          width: 66%;
        }

        &.col-6 {
          width: 50%;
        }

        &.col-3 {
          width: 25%;
        }

        &.col-4 {
          width: 33.3%;
        }
      }
    }
  }
}

.three-column-grid {
  padding-bottom: 2rem;

  .grid {
    display: flex;
    justify-content: center;
  }

  .center-h {
    margin: 0 auto;
  }

  .main-column {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    margin: 0;
  }

  @include tablet() {
    .left-column,
    .right-column,
    .aside {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 25%;
      margin: 0;
    }

    .main-column {
      flex-basis: 50%;
    }
  }
}
