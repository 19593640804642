@import "../m-variables";
@import "responsive";
@import "../mixins";

$size-circle-mobile: 8em;
$size-circle-desktop: 10em;

.p-box {
  width: 9rem;
  height: 8.4rem;
  color: $black;
  text-align: center;
  margin: 0 0 2.4rem 0;
  position: relative;

  @include desktop() {
    width: 12rem;
    height: 10rem;
  }

  // group hover effects to be triggered at same time
  &:hover {
    .p-box__title {
      color: $primary-color;
    }

    .p-box__circle {
      background: $profile-yellow;
    }

    figure img {
      transform: scale(1.2);
      animation-name: bounce;
      animation-duration: 0.3s;
      animation-timing-function: ease;
    }
  }

  // custom circle hovers
  &__grey-back > .p-box__circle { background: $lightgrey; }
  &__white-back > .p-box__circle { background: $profile-cream; }

  &__circle {
    width: $size-circle-mobile;
    height: $size-circle-mobile;
    position: absolute;
    left: calc(50% - #{$size-circle-mobile} / 2);
    top: -0.25em;
    background: $white;
    border-radius: 50%;
    transition: background 150ms ease-in;

    @include desktop() {
      width: $size-circle-desktop;
      height: $size-circle-desktop;
      left: calc(50% - #{$size-circle-desktop} / 2);
      top: -0.75em;
    }
  }

  &__link {
    text-decoration: none;
    margin: 0 0 3rem 0;
    width: 100%;
    display: flex;
    justify-content: center;

    &:last-child { margin: 0; }

    @include tablet() {
      margin: 0;
      width: auto;
    }
  }

  &__title {
    width: 100%;
    font-size: $font-small;
    text-align: center;
    font-style: italic;
    font-weight: 600;
    text-decoration: none;

    @include desktop() {
      margin-left: 0.5rem;
    }
  }

  &__intro {
    width: 100%;
    margin-top: -0.6rem;
    font-size: $font-smaller;
    text-align: center;
    font-style: italic;
    text-decoration: none;

    p {
      margin: 0;
    }
  }

  figure {
    width: 100%;
    height: 100%;
    z-index: map-get($z-indexs, product-box-figure);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      height: auto;
      max-height: 100%;
      max-width: 90%;
      margin-left: 1rem;
    }
  }
}

.p-box-row {
  width: 100%;
  margin: 2rem 0;
  padding: 1rem;
  text-align: center;

  @include desktop() {
    border-radius: $border-radius-boxCard;
    padding: 1rem 3rem;
  }

  &__white {
    background-color: $white;
  }

  &__blue {
    background-color: $light-blue;
  }

  &__items {
    max-width: $max-width;
    margin: 2rem auto;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;

    @include desktop() {
      justify-content: space-between;
    }
  }
}

@include animation-bouncing(bounce);
