@mixin termly-tablet {
   @media all and (min-width: 768px) {
      @content;
   }
}

@mixin termly-desktop {
   @media all and (min-width: 1024px) {
      @content;
   }
}

.termly {
   &__container {
      margin-left: 23.5px;
      margin-right: 23.5px;

      @include termly-tablet {
         margin-left: 10%;
         margin-right: 10%;
      }

      @include termly-desktop {
         margin-left: 20%;
         margin-right: 20%;
      }
   }

   &__text {
      width: 100%;
      text-align: center;
      line-height: 1.5;

      &--not-set {
         color: #d6001c; // TINE $seconday-color
      }

      &--edit {
         font-size: 0.8rem;
      }
   }
}
