// responsive classes
.hidden-sm {
  @include mobile() {
    display: none!important;
  }
}

.hidden-md {
  @include tablet() {
    display: none!important;
  }
}

.hidden-lg {
  @include desktop() {
    display: none!important;
  }
}

.hidden {
  display: none!important;
}
